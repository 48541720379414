import { h, FunctionalComponent, Fragment } from 'preact'
import { useState, useEffect, useCallback } from 'preact/hooks'
import useUser from '../../hooks/useUser'
import useSupabase from '../../hooks/useSupabase'
import useCurrentContact from '../../hooks/useCurrentContact'
import useScriptState from '../../hooks/useScriptState'
import { PhonebankMode } from '../../lib/types'
import { FastForwardIcon } from '@heroicons/react/solid'
import ConfirmModal from '../async/confirm-modal'

const SkipContactButton: FunctionalComponent<{ id: string, mode: PhonebankMode }> = ({ id, mode }) => {
  const supabase = useSupabase()
  const { user } = useUser()
  const { contact, mutateCurrentContact } = useCurrentContact(id, mode, false)
  const { hasQuestionResponses } = useScriptState(id, mode, false)
  const [showModal, setShowModal] = useState(false)
  const [error, setError] = useState(undefined as Error | undefined)

  useEffect(() => {
    if (error) {
      throw error
    }
  }, [error])

  const skipContact = useCallback(async () => {
    if (mode === PhonebankMode.NORMAL) {
      mutateCurrentContact(undefined, false)

      const [{ error: skipError }, { error: deleteError }] = await Promise.all([
        supabase.from('skipped_contacts')
          .insert({
            user: user!.id,
            phonebank: id,
            id: contact!.id
          }, {
            returning: 'minimal'
          }),
        supabase.from('results')
          .delete({ returning: 'minimal' })
          .match({
            phonebank: id,
            id: contact!.id
          })
          .limit(1)
      ])

      if (skipError || deleteError) {
        setError(new Error(`Error skipping contact: ${(skipError || deleteError)!.message}`))
      }
    }

    mutateCurrentContact(undefined, true)
  }, [contact?.id])

  const onClick = useCallback(async (e: any) => {
    e.preventDefault()

    // show a confirmation dialog if there is unsaved script state
    // that would be discarded by skipping the contact now
    if (hasQuestionResponses) {
      setShowModal(true)
    } else {
      return skipContact()
    }
  }, [hasQuestionResponses, skipContact])

  return (
    <>
      {showModal
        ? <ConfirmModal title='Discard Script Responses?'
          message='Skipping this contact will discard the script responses and another caller will be assigned to this contact. Would you like to continue?'
          confirmButtonText='Skip Contact'
          onConfirm={() => {
            setShowModal(false)
            skipContact()
          }}
          onDismiss={() => setShowModal(false)}
        />
        : null}
      <button
        onClick={onClick}
        type="button"
        class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-400 hover:text-gray-700 bg-white hover:bg-gray-50"
      >
        <FastForwardIcon class='h-4 w-4 mr-1' />
        Skip Contact
      </button>
    </>
  )
}

export default SkipContactButton
